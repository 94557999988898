import { db } from "context/firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { dateToYMD } from "utils/functions";

export type teacherType = {
  id: string;
  homeroom: number;
};

export async function loadTeachers() {
  const teacherCol = collection(db, "teacher");
  const teacherSnap = await getDocs(teacherCol);

  let teacherList: { [k: string]: teacherType } = {};

  teacherSnap.forEach((doc) => {
    teacherList[doc.data().name] = {
      id: doc.id,
      homeroom: doc.data().homeroom,
    };
  });

  return teacherList;
}

export type selflearnType = {
  id?: string;
  period: number;
  classroom?: string;
  workTeacher?: string;
  applied: boolean;
  approval?: {};
};

async function loadMaxPeriods(date: Date) {
  const selflearnConfigRef = doc(db, "selflearn", "_config");
  const selflearnConfigSnap = await getDoc(selflearnConfigRef);

  if (selflearnConfigSnap.exists()) {
    for (const [period, specialDates] of Object.entries(
      selflearnConfigSnap.data().period
    )) {
      if (period !== "default") {
        for (const specialDate of specialDates as Timestamp[]) {
          if (
            dateToYMD(date) === dateToYMD(new Date(specialDate.seconds * 1000))
          ) {
            return +period;
          }
        }
      }
    }
    return selflearnConfigSnap.data().period.default;
  }
}

export async function loadSelflearn(uid: string, date: Date) {
  const maxPeriod = await loadMaxPeriods(new Date(date.toDateString()));

  const selflearnCol = collection(db, "selflearn");
  const mySelflearnQ = query(
    selflearnCol,
    where("user", "==", uid),
    where("date", "==", Timestamp.fromDate(new Date(date.toDateString())))
  );

  const mySelflearnsSnap = await getDocs(mySelflearnQ);

  let totalSelflearn: selflearnType[] = [];

  for (let i = 0; i < maxPeriod; i++) {
    totalSelflearn.push({ period: i + 1, applied: false });
  }

  mySelflearnsSnap.forEach((selflearn) => {
    totalSelflearn[selflearn.data().period - 1] = {
      id: selflearn.id,
      period: selflearn.data().period,
      classroom: selflearn.data().classroom,
      workTeacher: selflearn.data().workTeacher,
      applied: true,
      approval: selflearn.data().approval,
    };
  });
  return totalSelflearn;
}

async function checkSelflearnExists(user: string, period: number, date: Date) {
  const selflearnCol = collection(db, "selflearn");
  const duplicateSelflearnQ = query(
    selflearnCol,
    where("user", "==", user),
    where("date", "==", Timestamp.fromDate(new Date(date.toDateString()))),
    where("period", "==", period)
  );

  const mySelflearnsSnap = await getDocs(duplicateSelflearnQ);

  return !mySelflearnsSnap.empty;
}

type approvalType = "pending" | "approved" | "rejected";

type selflearnFormType = {
  user: string;
  period: number;
  workTeacher: string;
  homeroomTeacher: string;
  classroom: string;
  date: Timestamp;
  attendance: { student: boolean; teacher: boolean };
  approval: { homeroomTeacher: approvalType; workTeacher: approvalType };
};

export async function applySelfLearn(
  user: string,
  data: {
    period: number;
    workTeacher: string;
    homeroomTeacher: string;
    classroom: string;
    date: Date;
  }
) {
  if (await checkSelflearnExists(user, data.period, data.date)) {
    // duplicate
    return false;
  }

  const selflearnForm: selflearnFormType = {
    user: user,
    period: data.period,
    workTeacher: data.workTeacher,
    homeroomTeacher: data.homeroomTeacher,
    classroom: data.classroom,
    date: Timestamp.fromDate(new Date(data.date.toDateString())),
    attendance: { student: false, teacher: false },
    approval: { homeroomTeacher: "pending", workTeacher: "pending" },
  };

  console.log(selflearnForm);
  await addDoc(collection(db, "selflearn"), selflearnForm);
}

export async function removeSelfLearn(id: string) {
  const targetDoc = doc(db, "selflearn", id);
  await deleteDoc(targetDoc);
}

export function calcApproval(approval: {}):
  | "pending"
  | "approved"
  | "rejected" {
  const approvals = Object.values(approval);
  if (approvals.includes("rejected")) {
    return "rejected";
  } else if (approvals.includes("pending")) {
    return "pending";
  } else {
    return "approved";
  }
}
