import { db } from "context/firebase";
import { collection, getDocs } from "firebase/firestore";

export function isEmpty(obj: {}) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

export function getKeyByValue(object: { [k: string]: any }, value: any) {
  return Object.keys(object).find((key) => object[key] === value);
}

export function getKeyByInnerElement(object: { [k: string]: {} }, element: {}) {
  return Object.keys(object).find(
    (key) =>
      Object.values(object[key]).includes(Object.values(element)[0]) &&
      getKeyByValue(object[key], Object.values(element)[0]) ===
        Object.keys(element)[0]
  );
}

export function getElementByOtherElement(
  object: { [k: string]: { [k: string]: any } },
  element: {},
  target: string
) {
  let key: string | undefined = getKeyByInnerElement(object, element);
  if (typeof key === "string") {
    return object[key][target];
  }
}

export function isEmail(string: string) {
  if (!string.match(/\S+@\S+\.\S+/)) {
    // Jaymon's / Squirtle's solution
    // Do something
    return false;
  }
  if (string.indexOf(" ") !== -1 || string.indexOf("..") !== -1) {
    // Do something
    return false;
  }
  return true;
}

export function leftPad(value: number, cipher: number = 2) {
  let result = value.toString();
  while (result.length < cipher) {
    result = "0" + result;
  }

  return result;
}

export function dateToHMS(date: Date) {
  const hour = leftPad(date.getHours(), 2);
  const minute = leftPad(date.getMinutes(), 2);
  const second = leftPad(date.getSeconds(), 2);

  return hour + minute + second;
}

export function dateToYMD(date: Date) {
  const year = date.getFullYear().toString();
  const month = leftPad(date.getMonth() + 1, 2);
  const day = leftPad(date.getDate(), 2);

  return year + month + day;
}

export function dateFromYMD(YMD: string) {
  return new Date(+YMD.slice(0, 4), +YMD.slice(4, 6) - 1, +YMD.slice(6, 8));
}

export function dateRelation(
  criteria: Date,
  target: Date,
  variant: "days" | "dayOfWeek" = "days"
): string {
  const crit = dateFromYMD(dateToYMD(criteria));
  const trgt = dateFromYMD(dateToYMD(target));
  const delta = (trgt.getTime() - crit.getTime()) / (24 * 3600 * 1000);
  if (delta === 0) {
    return "오늘";
  } else if (delta === -1) {
    return "어제";
  } else if (delta === -2) {
    return "그저께";
  } else if (delta === 1) {
    return "내일";
  } else if (delta === 2) {
    return "모레";
  } else {
    if (variant === "days") {
      if (delta > 0) {
        return delta.toString() + "일 후";
      } else {
        return Math.abs(delta).toString() + "일 전";
      }
    } else {
      return "구현 필요";
    }
  }
}

export type classroomType = {
  id: string;
  capacity: number;
  defaultTeacher: string;
  short: string;
};

export async function loadClassrooms() {
  const classroomCol = collection(db, "classroom");
  const classroomSnap = await getDocs(classroomCol);

  let classroomList: { [k: string]: classroomType } = {};

  classroomSnap.forEach((doc) => {
    classroomList[doc.data().name] = {
      id: doc.id,
      capacity: doc.data().capacity,
      defaultTeacher: doc.data().defaultTeacher,
      short: doc.data().short,
    };
  });

  return classroomList;
}

export async function loadUsers() {
  const userCol = collection(db, "user");
  const userSnap = await getDocs(userCol);

  let userList: { [id: string]: { [k: string]: string } } = {};

  userSnap.forEach((doc) => {
    userList[doc.id] = doc.data();
  });

  return userList;
}
// export async function fetchMaxPeriods(date: Date) {
//   const selflearnConfigRef = doc(db, "selflearn", "_config");
//   const selflearnConfigSnap = await getDoc(selflearnConfigRef);

//   if (selflearnConfigSnap.exists()) {
//     for (const [period, specialDates] of Object.entries(
//       selflearnConfigSnap.data().period
//     )) {
//       for (const specialDate of specialDates as Timestamp[]) {
//         console.log(typeof specialDates);
//         if (date.toDateString() === specialDate.toDate().toDateString()) {
//           console.log("겹친다!", period, "교시");
//           console.log(typeof period);
//           // setMaxPeriod(period);
//         }
//       }
//     }

//     // setMaxPeriod();
//   }
// }
