import { dateToHMS, dateToYMD, isEmpty } from "utils/functions";

export type mealType = {
  dateRel: string;
  variant: string;
  isAllergyShow: boolean;
  data: menuType;
};

export type menuType = {
  [code: string]: {
    calorie: number;
    menu: {
      allergy: string[];
      menu: string;
    }[];
    variant: string;
  };
};

export function suggestMealTime(date: Date) {
  const criteria = dateToHMS(date).slice(0, 4);

  const breakfastTime = "0710";
  const lunchTime = "1225";
  const dinnerTime = "1800";

  if (+breakfastTime - +criteria >= 0) {
    return [dateToYMD(date), "조식"];
  } else if (+lunchTime - +criteria >= 0) {
    return [dateToYMD(date), "중식"];
  } else if (+dinnerTime - +criteria >= 0) {
    return [dateToYMD(date), "석식"];
  } else {
    let tomorrow = new Date(date);
    tomorrow.setDate(date.getDate() + 1);

    return [dateToYMD(tomorrow), "조식"];
  }
}

export function mealToString(
  menu: {
    allergy: string[];
    menu: string;
  }[],
  isAllergyShow: boolean
) {
  if (menu === undefined) {
    return "";
  }

  let result = "";

  for (let data of menu) {
    result += data["menu"];

    if (isAllergyShow) {
      let AllergyCodeString = "";
      for (let AllergyCode of data["allergy"]) {
        if (AllergyCode !== null) {
          AllergyCodeString += AllergyCode + ". ";
        }
      }

      if (AllergyCodeString.length !== 0) {
        result += " | " + AllergyCodeString.trim();
      }
    }

    result += "\n";
  }

  return result;
}
