import * as React from "react";
import {
  Card,
  Chip,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { db } from "context/firebase";
import { doc, getDoc } from "firebase/firestore";
import {
  dateToYMD,
  isEmpty,
  getElementByOtherElement,
  dateRelation,
  dateFromYMD,
} from "utils/functions";
import { mealToString, mealType, suggestMealTime } from "./functions";

function Dashboard() {
  async function loadMeal(date: string) {
    const mealRef = doc(db, "meal", date);
    const mealSnap = await getDoc(mealRef);

    if (mealSnap.exists()) {
      return mealSnap.data();
    } else {
      return {};
    }
  }

  const [meal, setMeal] = React.useState<mealType>({
    dateRel: "오늘",
    variant: "중식",
    isAllergyShow: false,
    data: {},
  });

  React.useEffect(() => {
    async function init() {
      const today = new Date();
      const [targetMealYMD, targetMealVariant] = suggestMealTime(today);

      setMeal({
        ...meal,
        dateRel: dateRelation(today, dateFromYMD(targetMealYMD)),
        variant: targetMealVariant,
        data: await loadMeal(targetMealYMD),
      });
    }
    init();
  }, []);

  function handleMealVariantClick(e: React.MouseEvent) {
    setMeal({
      ...meal,
      variant: (e.target as HTMLElement).innerText,
    });
  }

  function handleMealAllergyClick() {
    setMeal({
      ...meal,
      isAllergyShow: !meal.isAllergyShow,
    });
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4} md={3}>
        <Card sx={{ width: "100%" }}>
          <Grid container sx={{ p: 1 }} spacing={1}>
            <Grid item xs={12}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <RestaurantIcon fontSize="small" />
                <Typography>{meal.dateRel}의 급식</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1}>
                {Object.values(meal.data).map((eachMeal) => (
                  <Chip
                    key={eachMeal.variant}
                    label={eachMeal.variant}
                    color={
                      meal.variant === eachMeal.variant ? "primary" : "default"
                    }
                    onClick={handleMealVariantClick}
                  />
                ))}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Paper variant="outlined" sx={{ p: 1 }}>
                <Typography style={{ whiteSpace: "pre-line" }}>
                  {mealToString(
                    getElementByOtherElement(
                      meal.data,
                      { variant: meal.variant },
                      "menu"
                    ),
                    meal.isAllergyShow
                  )}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper variant="outlined" sx={{ p: 1 }}>
                <FormControlLabel
                  control={<Switch size="small" />}
                  checked={meal.isAllergyShow}
                  label="알레르기 정보 표시"
                  onChange={handleMealAllergyClick}
                  sx={{ ml: 0 }}
                />
              </Paper>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Dashboard;
