// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA3G8fueSTP4M4iCuGSc_Bp2NPft_pPKTM",
  authDomain: "cbshself.firebaseapp.com",
  projectId: "cbshself",
  storageBucket: "cbshself.appspot.com",
  messagingSenderId: "325182176798",
  appId: "1:325182176798:web:0ea5eea258e19afae1780f",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { app, db, auth };
